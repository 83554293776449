var ismobile = '23';
var $window;
// var $header;
// var $navbar;
var fadeableContent;
var fadeableFooter;
var md;
// var $body;

var intersectionObserver;


var $panoContainerTop;
var $bgContainer;
var $bgSky;

jQuery(document).ready(function () {
    md = new MobileDetect(window.navigator.userAgent);
    // $body = $('body');
    $window = $(window);
    // $header = $('header');
    // $navbar = $('div.navbar');

    // $panoContainerTop = $header.find('.panorama--container-top');
    // $bgContainer = $('.bg--container');
    // $bgSky = $('.bg-sky');

    if (window.IntersectionObserver) {
        intersectionObserver = new IntersectionObserver(function(event) {
            event.forEach(function(entry) {
               if(entry.isIntersecting === true) {
                   entry.target.classList.add('fadeContentIn');
                   intersectionObserver.unobserve(entry.target);
               }
            });
        });
    }

    windowResize();
    $window.on('resize', function () {
        windowResize();
    });
    $window.on('scroll', function (e) {
        animateMenue(e);
    });

    if(typeof initOverlayMessage === 'function') {
        initOverlayMessage();
    }

    if (!intersectionObserver) {
        $window.on('scroll', function (e) {
            fadeContent();
        });
    }

    initFadeContent();

    if(ismobile) {
        initMobileFunctions();
    }

    $window.trigger('scroll');
});

function initFadeContent() {
    fadeableContent = $('article').find('.container-content').find('>div,>p,>h1,>h2,>strong').not('.no-fade');
    fadeableFooter = $('.footer').find('.footerinhalt');

    if(!intersectionObserver) {
        $.each(fadeableContent, function (i, v) {
            $(v).data().top = $(v).offset().top;
        });
        fadeableFooter.data().top = fadeableFooter.offset().top;
    } else {
        $.each(fadeableContent, function(i, v) {
            intersectionObserver.observe(v);
        });
        if (fadeableFooter.get(0)) {
            intersectionObserver.observe(fadeableFooter.get(0));
        }
    }
}

function fadeContent() {
    var offset = $window.scrollTop() + $window.height();
    $.each(fadeableContent, function (i, v) {
        if ($(v).data().top < offset) {
            $(v).addClass('fadeContentIn');
        }
    });
    if (fadeableFooter.data().top < offset) {
        fadeableFooter.addClass('fadeContentIn');
    }
}

function windowResize() {
    if (md.mobile()) {
        if (!ismobile || ismobile === '23') {
            ismobile = true;
            $('.header').css('transform', '');
        }
    } else {
        if (ismobile || ismobile === '23') {
            ismobile = false;
        }
    }
}

var menueCollapsed = false;

function animateMenue(e) {
    if (ismobile) return;
    var scrollhoehe = e.currentTarget.scrollY;

    var contentOffset = scrollhoehe / 5;

    var normalOffset = scrollhoehe / 12;
    if (normalOffset > 70) normalOffset = 70;


    if(scrollhoehe > 270 && !menueCollapsed) {
        menueCollapsed = true;
        $('header').addClass('collapsed');
    } else if(scrollhoehe <= 270 && menueCollapsed) {
        menueCollapsed = false;
        $('header').removeClass('collapsed');
    }

    // $bgContainer.css('transform', 'translateY('+contentOffset+'px)');
    // $bgSky.css('transform', 'translateY(-'+contentOffset+'px)');

    // $panoContainerTop.css('transform', 'translateY('+normalOffset+'px)');
    // $header.css('transform', 'translateY('+normalOffset+'px)');
}

function swapCalendar(element, event) {
    event.preventDefault();
    event.stopPropagation();
    var $this = $(element);
    var url = $this.attr('href');
    $.get(url, function(data) {
        data = $(data);
        data = data.find('.termine-row').html();
        $('.termine-outer-container .termine-row').html(data);
    });
}

function initMobileFunctions() {
    $('.mobilemenu__button').off('click').on('click', function () {
        $('.mobilemenu').addClass('open');
    });
    $('.mobilemenu__button-close, .mobilemenu').off('click').on('click', function () {
        $('.mobilemenu').removeClass('open');
        $('.mobilemenu__button-close').css('position', 'absolute');
    });
    $('.mobilemenu').on('transitionend', function (e) {
        if(parseInt(getComputedStyle(e.target).right) == 0) {
            $(e.target).find('.mobilemenu__button-close').css('position', 'fixed');
        } else {
            $('.mobilemenu').scrollTop(0);
        }
    });
}

